/* eslint-disable jsx-a11y/anchor-is-valid */
import '../stylesheets/projects.css';
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons';

const Projects = ({contentOpacity}) => {
    const previousIcon = <FontAwesomeIcon icon={faAngleLeft} />;
    const nextIcon = <FontAwesomeIcon icon={faAngleRight} />;

    let [current, setCurrent] = useState(0);
    let [currentImgIndex, setCurrentImgIndex] = useState(0);
    
    const [allProjects] = useState([
        {
            id:0, 
            name: "Ask Gandalf", 
            alt:"askGandalf", 
            description:'Welcome to "Ask Gandalf," a unique AI-powered application designed to unravel the mysteries of your ideas through the lens of various perspectives. Drawing inspiration from the legendary world of "The Lord of the Rings," this app invites users to explore the depths of their thoughts with a touch of magic.',
            howitworks:' "Ask Gandalf" takes your ideas on a transformative journey. Users input their thoughts, and the application, powered by advanced AI algorithms, analyzes them from different perspectives. The results are presented in a visually engaging manner, allowing users to gain a deeper understanding of their ideas.',
            why:'In a world filled with ideas, "Ask Gandalf" stands out as a beacon of wisdom and creativity. Whether you`re a creative enthusiast, a business innovator, or simply someone seeking a fresh perspective, this app is your trusted companion on the journey of idea exploration.',
            skills:"Html, CSS, JavaScript, React, Node.js, Git, GCP, Firestore, Pubsub, BigQuerry", 
            // imageUrl: require(`../assets/images/projects/askgandalf${currentImgIndex}.png`),
            imageUrl: "askgandalf/askgandalf",
            amountImgs:7,
            version:"",
            url:"https://askgandalf.mehmetkaantaspunar.se",
        },
        {
            id:1, 
            name: "Aganju´s Wrath v1", 
            alt:"aganjusWrathv1", 
            description:`Embark on a thrilling top-down gaming adventure with "Aganju´s Wrath," a dynamic game crafted using the Phaser game engine with passion and ingenuity. Immerse yourself in the action-packed world of Aganju as he battles formidable enemies known as "Hastur" using a variety of powerful skills.`,
            howitworks:`"Aganju´s Wrath" puts players in control of Aganju, a powerful character with a mission to survive and eliminate the menacing Hastur enemies. Players utilize Aganju's diverse skill set strategically to overcome challenges, progressing through levels of increasing difficulty.`,
            why:`"Aganju´s Wrath" is not just a game; it's an immersive experience that challenges players to master the art of survival. With Aganju's diverse skill set and the relentless onslaught of Hastur enemies, every level is a new adventure filled with excitement and strategic decision-making.`,
            skills:"Html, CSS, JavaScript, PHP, Insomnia, Github", 
            version:"Group Version",
            versionDetail:`Developed collaboratively by a talented group of friends — Kim Siebeneicher, Melinda Sowole, Ludvig Bode and Me. In this collaborative version, Aganju possesses four skills, each carefully designed by our team. While these skills do not have direct effects/animations on killing enemies, they contribute to the strategic aspect of gameplay, challenging players to use them wisely to navigate the game's challenges. Unlike the later version, this iteration of "Aganju's Wrath" does not feature in-game music. The focus is on the raw intensity of the battles and the immersive sounds of combat.`,
            imageUrl: "aganjuswrathv1/aganjuswrath",
            amountImgs:7,
            url:"https://wrathofaganjuv1.mehmetkaantaspunar.se"
        },
        {
            id:2, 
            name: "Wrath of Aganju v2", 
            alt:"aganjusWrathv2", 
            description:`Embark on an enhanced journey with "Wrath of Aganju," the result of dedicated improvements and upgrades by yours truly. In this version, I took the original creation to new heights, adding complexity and depth to the gameplay. Experience the evolution of Aganju's world, now featuring 16 skills, upgradable sword, shield, and strength enhancements.`,
            howitworks:``,
            why:`This version represents the evolution of "Wrath of Aganju," showcasing a commitment to enhancing gameplay, visuals, and overall player experience. The expanded skill set, upgradable equipment, and enriched audio-visual elements provide a more engaging and challenging adventure.`,
            skills:"", 
            version:"Upgraded Version",
            versionDetail:`In this upgraded version, Aganju boasts a total of 16 skills, providing players with a diverse set of abilities to master. The introduction of upgradable sword, shield, and strength further amplifies the strategic depth of the game. Experience the game with newer animations for impactful interactions, creating a more visually engaging and immersive gaming environment. The addition of music in the pause and home scenes enriches the overall atmosphere. Strategize your battles with the introduction of health indicators for enemies, offering players valuable information to plan their attacks and defenses effectively.`,
            imageUrl: "aganjuswrathv2/aganjuswrath",
            amountImgs:4,
            url:"https://wrathofaganjuv2.mehmetkaantaspunar.se"
        },
        {
            id:3, 
            name: "HediyApp", 
            alt:"hediyapp", 
            description:`Celebrate special moments with "HediyApp," a heartwarming birthday app crafted as a meaningful present for someone truly special. Designed with love, this app allows users to create albums, add photos, share favorite memories, and connect through heartfelt comments. With the ability to personalize profiles, "HediyApp" brings joy and connection to the world of birthday celebrations.`, 
            howitworks:`"HediyApp" turns the birthday celebration into a collaborative experience. Users create albums, upload photos, and share their favorite moments with friends and family. The interactive comments feature allows everyone to engage, reminisce, and celebrate together.`,
            why:`"HediyApp" is more than an app; it's a heartfelt gift designed to bring people closer during special occasions. The exclusivity of the current user base sets the stage for a broader release, where the simplicity of album creation, photo sharing, and profile personalization will touch the lives of a wider audience. "HediyApp" is on the verge of opening its doors to a wider audience. Soon, this heartwarming birthday app will be available to everyone, spreading joy and creating memorable celebrations.`,
            skills:"PHP, JavaScript, Css", 
            version:"",
            imageUrl: "hediyapp/hediyapp",
            amountImgs:8,
            url:"",
        },
        {
            id:4, 
            name: "Mr. HangMan", 
            alt:"mrhangman", 
            description:`Dive into the world of words with "Mr. HangMan," a captivating mobile game built using React Native. This hangman-style game challenges players to guess the correct word by clicking on letters. With the option to play solo or with a friend on the same device, and the added thrill of unlocking avatars with earned points, "Mr. HangMan" promises an engaging and entertaining gaming experience.`, 
            howitworks:`"Mr. HangMan" transforms the classic hangman game into a modern and interactive experience. Players can choose to tackle word challenges solo or challenge a friend for a duel of words on the same device. The inclusion of diverse word categories and the excitement of unlocking avatars with earned points keep the gameplay dynamic and rewarding.`,
            why:`"Mr. HangMan" brings a timeless word game into the mobile era, offering both a solo challenge and an entertaining local multiplayer option. The inclusion of unlockable avatars and diverse word categories ensures a rich and engaging gaming experience.`,
            skills:"JavaScript, Css and React Native with Expo CLI", 
            version:"",
            imageUrl: "mrhangman/mrhangman",
            amountImgs:11,
            url:"",
        },
        {
            id:5, 
            name: "Booking System - Cohabit", 
            alt:"cohabitbookingsystem", 
            description:`Experience seamless order management with the Cohabit Booking System. This comprehensive system allows customers to effortlessly add items to their order list and place orders. Upon placing an order, both the Cohabit team and the customer receive confirmation emails detailing the order and payment information. Alternative link: https://mehmet-kaan.github.io/cohabit/`, 
            howitworks:`The Cohabit Booking System streamlines the ordering process by enabling customers to compile an order list and submit their order with ease. Once an order is placed, an automated email is sent to the Cohabit team for processing, and a confirmation email with order and payment details is sent to the customer.`,
            why:`The Cohabit Booking System was designed to enhance the efficiency and reliability of the order management process. By providing immediate confirmation and detailed order information, it ensures both the team and customers are well-informed, reducing the risk of errors and improving overall satisfaction.`,
            skills:"JavaScript, CSS, and Nodejs - Backend development for email automation and order processing", 
            version:"",
            imageUrl: "cohabit/cohabit",
            amountImgs:7,
            url:"https://orderwizard.cohabit.se/",
        },
        {
            id:6, 
            name: "LogBotAI", 
            alt:"logbotai", 
            description: "LogBotAI is an AI-powered application designed to deliver more accurate and context-specific answers based on uploaded documents. Unlike other large language models, LogBotAI focuses on understanding the content of your files, providing reliable and tailored responses to your questions.",
            howitworks: "Simply upload your documents to LogBotAI, which processes and analyzes the content. By interacting with the chatbot, you receive accurate and relevant answers based on the specific information within your files.",
            why: "LogBotAI was created to address the limitations of existing LLMs in providing document-specific answers. By focusing on the content of uploaded documents, LogBotAI delivers more accurate and contextually relevant responses, improving the reliability of AI-powered document analysis for users.",
            skills:"JavaScript, CSS, Nodejs, GCP, Github", 
            version:"",
            imageUrl: "logbotai/logbotai",
            amountImgs:6,
            url:"https://logbotai-380524912014.europe-west1.run.app/",
        }
    ]);

    // const [selectedProject, setSelectedProject] = useState(allProjects[current]);
      
    const [selectedProject, setSelectedProject] = useState(null);

    //Navigates between projects
    const navigateProjects = (direction) => {

        if (direction === "next") {
            if (current < allProjects.length - 1) {
                current++;
                setCurrent(current);
            } else {
                current = 0;
                setCurrent(current);
            }
        } 
        else {
            if (current > 0) {
                current--;
                setCurrent(current);
            } else {
                current = allProjects.length - 1;
                setCurrent(current);
            }
        }

        setCurrentImgIndex(0);
        setSelectedProject(allProjects[current]);
    };
    
    //Navugates between Images of selectedProject
    const navigateImages = (direction) => {
        if (direction === "next") {
            if (currentImgIndex < selectedProject.amountImgs-1) {
                currentImgIndex++;
                setCurrentImgIndex(currentImgIndex);
            } else {
                currentImgIndex = 0;
                setCurrentImgIndex(currentImgIndex);
            }
        } 
        else {
            if (currentImgIndex > 0) {
                currentImgIndex--;
                setCurrentImgIndex(currentImgIndex);
            } else {
                currentImgIndex = (selectedProject.amountImgs - 1);
                setCurrentImgIndex(currentImgIndex);
            }
        }
    }

    const navigateDots = (index) => {
        setCurrent(index);
        setCurrentImgIndex(0);
        setSelectedProject(allProjects[index]);
    }

    return ( 
        <div className="content">
            <div className="projectsContent" style={{
                opacity:contentOpacity
                }}>
                  {selectedProject == null &&  <h1 className='projectsTitle'>Projects</h1>}  
                <div className="selectedProject">
                    {selectedProject !== null ? 
                    <div className='selectedProjectContent'>
                        <a className='backToProjectsBtn' onClick={() => setSelectedProject(null)}>{previousIcon} Projects</a>
                        <div className='imgContent'>
                            <button className='imgNavigateBtn preImg' onClick={()=> navigateImages("prev")}>{previousIcon}</button>
                            {selectedProject !== null && <img loading='lazy' className='selectedProjectImg' src={require(`../assets/images/projects/${selectedProject.imageUrl}${currentImgIndex}.png`)} alt={selectedProject.alt}/>}
                            <button className='imgNavigateBtn nextImg' onClick={()=> navigateImages("next")}>{nextIcon}</button>
                        </div>
                        <div className="projectInfo">
                            <h2 className='selectedProjectName'>
                                <span>Project:&nbsp;</span> {selectedProject.name}
                            </h2>
                            <div className='selectedProjectDesc'>
                                <span>About:&nbsp;</span> {selectedProject.description}
                            </div>
                            {selectedProject.skills && 
                                <div className='selectedProjectDesc'>
                                  <span>Skills:&nbsp;</span> {selectedProject.skills}
                                </div>
                            }
                            {selectedProject.howitworks && 
                                <div className='selectedProjectDesc'>
                                    <span>How it Works:&nbsp;</span> {selectedProject.howitworks}
                                </div>
                            }
                            <div className='selectedProjectDesc'>
                                <span>Why "{selectedProject.name}":&nbsp;</span> {selectedProject.why}
                            </div>
                            {selectedProject.version &&  
                                <div className='selectedProjectDesc'>
                                    <span>"{selectedProject.version}":&nbsp;</span> {selectedProject.versionDetail}
                                </div>
                            }
                        </div>
                        {selectedProject.url && <a className='selectedProjectLink' target='_blank' rel='noopener noreferrer' href={selectedProject.url}>To Project {nextIcon}</a>}
                    </div>
                    : 
                        <div className='projectsContainer'>
                            <p>Here are some of my projects, to see more you are welcome to visit my <a href="https://github.com/Mehmet-Kaan">Github</a> repository.</p>                            
                            
                            <div className="projectsView">
                                {allProjects.map(project => {
                                    const imageURL = require(`../assets/images/projects/${project.imageUrl}0.png`);
                                    return (
                                        <div 
                                            className="projectBox" 
                                            key={project.id} 
                                            style={{
                                                backgroundImage: `url(${imageURL})`
                                            }}
                                            onClick={()=> {
                                                setCurrent(project.id);
                                                setCurrentImgIndex(0);
                                                setSelectedProject(project);
                                            }}
                                        >
                                            <p className='projectBoxNameP'>{project.name}</p>    
                                            <div className='darkTilt'></div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>    
                    }
                </div>
                {selectedProject !== null && 
                    <div className="navigatorProjects">
                        <button className="navigateBtn preProjectBtn" onClick={()=> navigateProjects("prev")}>{previousIcon}</button>
                        <h2 className="selectedProjectTitle">
                                {selectedProject.name}
                            </h2>
                        <button className="navigateBtn nextProjectBtn" onClick={()=> navigateProjects("next")}>{nextIcon}</button>
                        <div className="navigateDots">
                            {Array.from({ length: allProjects.length }, (_, index) => (
                                <div key={index} className={current === index ? "dot activeDot" : "dot"} onClick={()=> navigateDots(index)}></div>
                            ))}
                        </div>
                    </div>
                }
            </div>
        </div>
     );
}
 
export default Projects;